import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { from, HttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";

import { setContext } from "@apollo/client/link/context";
import User from "store/user";
import Emitter from "functions/emitter";

const ErrorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) console.log("graphQLErrors", graphQLErrors);
	if (networkError) console.log("networkError", networkError);

	if (networkError?.statusCode === 401) {
		Emitter.emit("LOGOUT");
	}
});

console.log(process.env.REACT_APP_API);

const uploadLink = createUploadLink({
	uri: process.env.REACT_APP_API,
});

const AuthLink = setContext(async (_, { headers }) => {
	let userData = await localStorage.getItem("user");
	let token;
	if(userData) {
		userData = JSON.parse(userData);
		if(userData.token) {
			token = userData.token;
		}
	}
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

const DataSourceLink = setContext(async (_, { headers }) => {
	let sourceId = await localStorage.getItem("dataSourceId");
	return {
		headers: {
			...headers,
			"X-DataSourceId": sourceId ?? ""
		},
	};
});

const additiveLink = from([ErrorLink, AuthLink, DataSourceLink, uploadLink]);

const client = new ApolloClient({
	link: additiveLink,
	cache: new InMemoryCache({
		addTypename: false,
	}),
});

export default client;
