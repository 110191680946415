import { gql } from '@apollo/client';

export const USERS = gql`
  query Users($filters: UserListFilters, $page: Int!) {
    Users(filters: $filters, page: $page) {
      users {
        _id
        email
        name
        cpf
        cnpj
        phone
        status
        plan
        has_due
      }
      pages
      currentPage
      total
    }
  }
`;