import React, { useState } from "react";
import * as Queries from "./Queries";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

import { Card, Col, Row, Layout, Typography, Divider, Badge, Empty } from "antd";

const { Content } = Layout;
const { Title } = Typography;

function Dashboard() {

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
						<Empty/>
				</div>
			</Content>
		</Layout>
	);
}

export default Dashboard;
