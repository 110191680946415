import { useEffect, useState } from "react";

import { Menu, Layout, Select } from "antd";

import { gql, useQuery } from "@apollo/client";

import {
	AppstoreOutlined,
	UserOutlined,
	StarOutlined,
	DollarOutlined,
} from "@ant-design/icons";
import CertificatePurchase from "components/CertificatePurchase";
import useStore from "store/main";

const { SubMenu } = Menu;
const { Sider } = Layout;

function View(props) {
	const [dataSource, setDataSource] = useState(null);
	const [keys, setKeys] = useState(["/"]);

	const mainStore = useStore((state) => state);

	const dataSources = useQuery(
		gql`
		{
			DataSources {
				id
				name
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const handleClick = (e) => {
		props.history.push(e.key);
	};

	const setActiveRoute = () => {
		const { pathname } = props.history.location;
		if (pathname.indexOf("/user") > -1) {
			setKeys(["/users"]);
		} else if (pathname.indexOf("/partner") > -1) {
			setKeys(["/partners"]);
		} else {
			setKeys([pathname]);
		}
	};

	useEffect(() => {
		props.history.listen(() => {
			setActiveRoute();
		});
		setActiveRoute();
		(async () => {
			let id = await localStorage.getItem("dataSourceId");
			if (id) {
				setDataSource(id);
				mainStore.setDataDource(id);
			}
		})();
	}, []);

	return (
		<div>
			<Sider width={280} className={"sider"}>
				<div className="logo-wrapper">
					<img
						src={
							require(`themes/${process.env.REACT_APP_THEME}/images/login-logo.png`)
								.default
						}
						alt=""
					/>
				</div>

				<div className="dataSource-select">
					<Select
						loading={dataSources?.loading}
						disabled={dataSources.loading}
						style={{
							width: "100%",
						}}
						placeholder={"Selecione uma fonte"}
						value={dataSource}
						onChange={async (e) => {
							await localStorage.setItem("dataSourceId", e);
							setDataSource(e);
							mainStore.setDataDource(e);
						}}
					>
						{dataSources?.data?.DataSources?.length
							? dataSources?.data?.DataSources.map((item) => {
									return (
										<Select.Option value={item.id}>{item.name}</Select.Option>
									);
							  })
							: null}
					</Select>
				</div>

				<Menu
					onClick={handleClick}
					style={{ width: 280 }}
					defaultSelectedKeys={keys}
					selectedKeys={keys}
					defaultOpenKeys={["sub1", "sub1-1"]}
					mode="inline"
				>
					<Menu.Item icon={<AppstoreOutlined />} key={"/"}>
						Dashboard
					</Menu.Item>

					<Menu.SubMenu key={'sub1'} icon={<DollarOutlined />} title={"Financeiro"}>
						<Menu.SubMenu key={'sub1-1'} icon={<DollarOutlined />} title={"Contas a Receber"}>
							<Menu.Item key={"/finance/mrr"}>MRR</Menu.Item>
							<Menu.Item key={"/finance/one-off"}>Pontual</Menu.Item>
						</Menu.SubMenu>
					</Menu.SubMenu>

					<Menu.Item icon={<StarOutlined />} key={"/admins"}>
						Administradores
					</Menu.Item>
				</Menu>

				<CertificatePurchase />
			</Sider>
		</div>
	);
}

export default View;
