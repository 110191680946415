import React from "react";
import { Route, Switch } from "react-router-dom";
// Scenes Imports

import Dashboard from "./dashboard";
import * as MRR from "./users/mrr";
import * as OneOff from "./users/oneoff";
import Admins from "./admins";

import NotFound from './not-found';

// Routes Config

const routes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/finance/mrr",
    exact: true,
    component: MRR.List,
  },
  {
    path: "/finance/mrr/:id",
    exact: true,
    component: MRR.User,
  },
  {
    path: "/finance/one-off",
    exact: true,
    component: OneOff.List,
  },
  {
    path: "/admins",
    exact: true,
    component: Admins,
  }
];

// Routes Functions

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact ? true : false}
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );
}

function RouteConfig() {
  let returnArray = [];
  routes.map((route, i) => {
    let routeObj = {
      ...route
    };
    returnArray.push(<RouteWithSubRoutes key={i} {...routeObj} />);
  });

  

  //return returnArray;

  return (
    <Switch>
        {returnArray}
        <Route component={NotFound}/>
    </Switch>
  )
}

export default RouteConfig;
