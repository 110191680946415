import { useState, useEffect, useReducer, useRef } from "react";
import * as Queries from "./Queries";
import { useLazyQuery } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

import Mask from "functions/mask";

import { EditOutlined } from "@ant-design/icons";

import MaskedInput from "antd-mask-input";
import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Select,
	Form,
	Input,
	Badge,
	Tooltip,
	DatePicker,
} from "antd";
import { statuses } from "./Misc";
import useStore from "store/main";
import { useImmer } from "use-immer";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

function Users(props) {
	const mounted = useRef(false);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const [loadingPagination, setLoadingPagination] = useState(false);

	const [load, { loading, data, error, refetch, called }] = useLazyQuery(
		Queries.USERS,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [filters, setFilters] = useImmer({
		status: "1",
	});

	const [pages, setPages] = useState({
		current: 1,
	});

	useEffect(() => {
		filter();
		mounted.current = true;

		useStore.subscribe(
			(state) => state.dataSource,
			(state, nextState) => {
				if (nextState) {
					console.log(nextState);
					filter();
				}
			}
		);
	}, []);

	const filter = () => {
		setPages({ current: 1 });
		load({
			variables: {
				filters,
				page: 1,
			},
		});
	};

	const hasFilters = () => {
		let has = false;
		for (var prop in filters) {
			if (filters[prop] || filters[prop] !== "") has = true;
		}
		return has;
	};

	const clearFilters = () => {
		setPages({ current: 1 });
		setFilters({
			status: "1",
		});
		forceUpdate();
		load({
			variables: {
				page: 1,
			},
		});
	};

	const columns = [
		{
			title: "Nome",
			dataIndex: "name",
			key: "name",
			render: (value, row) => (
				<div>{row.has_due && (<Badge status="warning" />)}{value}</div>
			)
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (email) => email || "-",
		},
		{
			title: "Fone",
			dataIndex: "phone",
			key: "phone",
			render: (phone) => {
				return phone ? Mask(phone, "(99) 99999-9999") : "-";
			},
		},
		{
			title: "CNPJ",
			dataIndex: "cnpj",
			key: "cnpj",
			render: (cnpj) => cnpj || "-",
		},
		{
			title: "Empresa",
			dataIndex: "company",
			key: "company",
			render: (value, row) => row?.partner_company?.name || "-",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status) => {
				return statuses[status];
			},
		},
		{
			title: "Ações",
			dataIndex: "",
			key: "x",
			width: 50,
			render: (value, row) => {
				return (
					<div>
						<Tooltip title="Editar">
							<Button
								shape="circle"
								icon={<EditOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									props.history.push(`mrr/${row._id}`);
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
	];

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>MRR</Title>
				</div>

				<div style={{ marginBottom: "30px" }}>
					<Row gutter={20}>
						<Col flex="auto">
							<Row gutter={20}>
								<Col span={8}>
									<Form.Item>
										<Input
											placeholder="Nome do Cliente"
											value={filters?.name}
											onChange={(e) => {
												setFilters((d) => {
													d.name = e.target.value;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item>
										<MaskedInput
											mask={"11.111.111/1111-11"}
											placeholder="Buscar por CNPJ"
											value={filters?.cnpj}
											onChange={(e) => {
												setFilters((d) => {
													d.cnpj = e.target.value;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item>
										<DatePicker.RangePicker
											format={'DD/MM/YYYY'}
											value={filters?.period}
											onChange={(e) => {
												setFilters(d => {
													d.period = e;
												})
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item>
										<Input
											placeholder="Buscar por E-mail"
											value={filters?.email}
											onChange={(e) => {
												setFilters((d) => {
													d.email = e.target.value;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item>
										<Select
											value={filters?.status}
											onChange={(e) => {
												setFilters((d) => {
													d.status = e;
												});
											}}
											placeholder={"Status"}
										>
											<Select.Option value="">Qualquer</Select.Option>
											<Select.Option value="0">Expirado</Select.Option>
											<Select.Option value="1">Ativo</Select.Option>
											<Select.Option value="2">
												Não cadastrou/requisitou empresa
											</Select.Option>
											<Select.Option value="3">
												Aguardando Abertura
											</Select.Option>
											<Select.Option value="4">Inativo</Select.Option>
											<Select.Option value="5">Cancelado</Select.Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col>
							<Button onClick={filter}>Filtrar</Button>

							{hasFilters() ? (
								<Button onClick={clearFilters} style={{ marginLeft: "10px" }}>
									Limpar
								</Button>
							) : null}
						</Col>
					</Row>
				</div>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data?.Users?.users?.length ? data.Users.users : []}
								columns={columns}
								loading={loadingPagination}
								pagination={{
									defaultCurrent: data?.Users?.currentPage,
									total: data?.Users?.total,
									showSizeChanger: false,
									defaultPageSize: 10,
								}}
								onChange={async (pagination) => {
									setLoadingPagination(true);
									await refetch({
										page: pagination.current,
										filters,
									});
									setLoadingPagination(false);
								}}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>
			</Content>
		</Layout>
	);
}

export default Users;
