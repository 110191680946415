import { gql } from '@apollo/client';

export const ONEOFF = gql`
  query OneOffEntries($page: Int!, $filters: OneOffEntriesFilters) {
    OneOffEntries(page: $page, filters: $filters) {
      entries {
        _id
        date
        client_name
        service
        foreign_id
        amount
        invoice_status
        file
        payment_method
        due_date
        payment_date
        payment_status
      }
      pages
      currentPage
      total
    }
  }
`;