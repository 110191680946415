import { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import { useImmer } from "use-immer";
import { EditOutlined, UploadOutlined, FileOutlined } from "@ant-design/icons";
import {
	Layout,
	Table,
	Typography,
	Button,
	Select,
	Form,
	Tooltip,
	Modal,
	DatePicker,
	Upload,
	Input,
	Tag,
	Row,
	Col,
} from "antd";
import moment from "moment";
import useStore from "store/main";

import UserSearch from "components/UserSearch";
import { USERS } from "../mrr/Queries";

import InputNumber from "components/InputNumber";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

function Users(props) {
	const [loadingPagination, setLoadingPagination] = useState(false);

	const [page, setPage] = useImmer(0);
	const [filters, setFilters] = useImmer({});

	const [modal, setModal] = useImmer({
		visible: false,
	});

	const [load, { loading, data, error, refetch }] = useLazyQuery(
		Queries.ONEOFF,
		{
			fetchPolicy: "no-cache",
			variables: {
				page: 1,
				filters: {},
			},
		}
	);

	useEffect(() => {
		filter();

		useStore.subscribe(
			(state) => state.dataSource,
			(state, nextState) => {
				if (nextState) {
					console.log(nextState);
					filter();
				}
			}
		);
	}, []);

	const filter = () => {
		load({
			variables: {
				filters: { ...filters },
				page: 1,
			},
		});
	};

	const columns = [
		{
			title: "Cliente",
			dataIndex: "client_name",
			key: "client_name",
			render: (value) => value ?? "-",
		},
		{
			title: "Data",
			dataIndex: "date",
			key: "date",
			render: (value) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
		},
		{
			title: "Valor",
			dataIndex: "amount",
			key: "amount",
			render: (value) =>
				value
					? value.toLocaleString("pt-BR", {
							style: "currency",
							currency: "BRL",
					  })
					: "-",
		},
		{
			title: "Data Venc.",
			dataIndex: "due_date",
			key: "due_date",
			render: (value) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
		},
		{
			key: "invoice_status",
			dataIndex: "invoice_status",
			title: "Nota Fiscal",
			render: (value) => {
				let label;
				switch (value) {
					case "created":
						label = "Emitida";
						break;
					case "to_be_created":
						label = "A emitir";
						break;
					default:
						label = "Enviado contabilidade";
						break;
				}
				return <Tag>{label}</Tag>;
			},
		},
		{
			key: "payment_status",
			dataIndex: "payment_status",
			title: "Status do Pagamento",
			render: (value) => {
				let label, color;

				switch (value) {
					case "paid":
						label = "Pago";
						color = "green";
						break;
					case "due":
						label = "A vencer";
						color = "gold";
						break;
					default:
						label = "Vencido";
						color = "red";
						break;
				}
				return <Tag color={color}>{label}</Tag>;
			},
		},
		{
			key: "file",
			dataIndex: "file",
			title: "Arquivo",
			width: 100,
			render: (value) => {
				if (value)
					return (
						<Button
							shape="circle"
							onClick={() => {
								window.open(value);
							}}
						>
							<FileOutlined />
						</Button>
					);

				return null;
			},
		},
		{
			title: "Ações",
			dataIndex: "",
			key: "x",
			width: 50,
			render: (value, row) => {
				return (
					<div>
						<Tooltip title="Editar">
							<Button
								shape="circle"
								icon={<EditOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									setModal({
										visible: true,
										data: row,
									});
								}}
							/>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const clearFilters = () => {
		setFilters({});
		refetch({
			variables: {
				page: 1,
			},
		});
	};

	const hasFilters = () => {
		let has = false;
		for (var prop in filters) {
			if (filters[prop] || filters[prop] !== "") has = true;
		}
		return has;
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Pontual</Title>

					<Button
						type="primary"
						onClick={() => {
							setModal({
								visible: true,
							});
						}}
					>
						Adicionar
					</Button>
				</div>

				<div style={{ marginBottom: "30px" }}>
					<Form layout="vertical">
						<Row gutter={20}>
							<Col flex="auto">
								<Row gutter={20}>
									<Col span={6}>
										<Form.Item label={"Cliente"}>
											<UserSearch
												query={USERS}
												value={filters?.client_name}
												onChange={(e, r) => {
													setFilters((d) => {
														d.foreign_id = e;
														d.client_name = r.name;
													});
												}}
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item label={"Status Nota Fiscal"}>
											<Select
												value={filters?.invoice_status}
												onChange={(e) => {
													setFilters((d) => {
														d.invoice_status = e;
													});
												}}
											>
												<Select.Option value={"created"}>Emitida</Select.Option>
												<Select.Option value={"to_be_created"}>
													A emitir
												</Select.Option>
												<Select.Option value={"sent_accountant"}>
													Enviado contabilidade
												</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item label={"Data de Vencimento"}>
											<DatePicker.RangePicker
												format={"DD/MM/YYYY"}
												style={{
													width: "100%",
												}}
												value={
													filters?.due_date?.length
														? [
																moment(filters.due_date[0]),
																moment(filters.due_date[1]),
														  ]
														: []
												}
												onChange={(e) => {
													setFilters((d) => {
														d.due_date = e.map((i) => {
															return i.toISOString();
														});
													});
												}}
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item label={"Status do Pagamento"}>
											<Select
												value={filters?.payment_status}
												onChange={(e) => {
													setFilters((d) => {
														d.payment_status = e;
													});
												}}
											>
												<Select.Option value={"paid"}>Pago</Select.Option>
												<Select.Option value={"due"}>A vencer</Select.Option>
												<Select.Option value={"overdue"}>Vencido</Select.Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Col>
							<Col
								style={{
									paddingTop: 30,
								}}
							>
								<Button onClick={filter}>Filtrar</Button>

								{hasFilters() ? (
									<Button onClick={clearFilters} style={{ marginLeft: "10px" }}>
										Limpar
									</Button>
								) : null}
							</Col>
						</Row>
					</Form>
				</div>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data?.OneOffEntries?.entries}
								columns={columns}
								loading={loadingPagination}
								pagination={{
									defaultCurrent: data?.OneOffEntries?.currentPage,
									total: data?.OneOffEntries?.total,
									showSizeChanger: false,
									defaultPageSize: 10,
									hideOnSinglePage: true,
								}}
								onChange={async (pagination) => {
									setLoadingPagination(true);
									await refetch({
										page: pagination.current,
									});
									setLoadingPagination(false);
								}}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>
			</Content>

			<OneOffModal
				{...modal}
				refetch={refetch}
				close={() => {
					setModal({
						visible: false,
					});
				}}
			/>
		</Layout>
	);
}

function OneOffModal({ visible, close, data, refetch }) {
	const [state, setState] = useImmer({});

	const [saveOneOff, { loading: loadingSave }] = useMutation(
		gql`
    mutation OneOffEntry($input: OneOffEntry_Input, $entry_id: String) {
      OneOffEntry(input: $input, entry_id: $entry_id)
    }
  `,
		{
			onCompleted: (res) => {
				if (res?.OneOffEntry) {
					onClose();
					refetch();
				}
			},
			onError: (err) => {
				console.log(err);
			},
		}
	);

	const onClose = () => {
		close();
		setState({});
	};

	const save = async () => {
		let { _id, client_name, ...payload } = state;
		if (payload.date) payload.date = payload.date.toISOString();
		if (payload.due_date) payload.due_date = payload.due_date.toISOString();
		if (payload.payment_date)
			payload.payment_date = payload.payment_date.toISOString();

		console.log(payload);

		await saveOneOff({
			variables: {
				input: payload,
				entry_id: data?._id ? data._id : null,
			},
		});
	};

	useEffect(() => {
		if (data && visible) {
			let stateData = { ...data };
			if (stateData.date) stateData.date = moment(stateData.date);
			if (stateData.due_date) stateData.due_date = moment(stateData.due_date);
			if (stateData.payment_date)
				stateData.payment_date = moment(stateData.payment_date);

			delete stateData.file;

			setState(stateData);
		}
	}, [data, visible]);

	return (
		<Modal
			visible={visible}
			onCancel={() => {
				onClose();
			}}
			title={data?._id ? "Editar Entrada" : "Nova Entrada"}
			footer={[
				<Button key="back" onClick={onClose} disabled={loadingSave}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loadingSave}
					disabled={loadingSave}
					onClick={save}
				>
					Enviar
				</Button>,
			]}
		>
			<Form layout="vertical">
				<Form.Item label={"Data"}>
					<DatePicker
						style={{
							width: "100%",
						}}
						format={"DD/MM/YYYY"}
						value={state?.date}
						onChange={(e) => {
							setState((d) => {
								d.date = e;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Cliente"}>
					<UserSearch
						query={USERS}
						value={state?.client_name}
						onChange={(e, r) => {
							setState((d) => {
								d.foreign_id = e;
								d.client_name = r.name;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Serviço Vendido"}>
					<Input
						value={state?.service}
						onChange={(e) => {
							setState((d) => {
								d.service = e.target.value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Valor do Serviço"}>
					<InputNumber
						value={state?.amount}
						onChange={(e) => {
							setState((d) => {
								d.amount = e;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Status Nota Fiscal"}>
					<Select
						value={state?.invoice_status}
						onChange={(e) => {
							setState((d) => {
								d.invoice_status = e;
							});
						}}
					>
						<Select.Option value={"created"}>Emitida</Select.Option>
						<Select.Option value={"to_be_created"}>A emitir</Select.Option>
						<Select.Option value={"sent_accountant"}>
							Enviado contabilidade
						</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label={"Arquivo"}>
					<div
						style={{
							display: "flex",
							gap: 10,
						}}
					>
						<Upload
							maxCount={1}
							accept={".pdf,.gif,.jpg,.jpeg,.png"}
							fileList={state?.file ? [state.file] : []}
							beforeUpload={() => false}
							onChange={(e) => {
								setState((d) => {
									d.file = e.file;
								});
							}}
						>
							<Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
						</Upload>
						{data?.file ? (
							<Button
								type="primary"
								onClick={() => {
									window.open(data.file);
								}}
							>
								Ver arquivo
							</Button>
						) : null}
					</div>
				</Form.Item>

				<Form.Item label={"Forma de Pagamento"}>
					<Select
						value={state?.payment_method}
						onChange={(e) => {
							setState((d) => {
								d.payment_method = e;
							});
						}}
					>
						<Select.Option value={"boleto"}>Boleto</Select.Option>
						<Select.Option value={"card"}>Cartão</Select.Option>
						<Select.Option value={"pix"}>PIX</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label={"Data de Vencimento"}>
					<DatePicker
						style={{
							width: "100%",
						}}
						format={"DD/MM/YYYY"}
						value={state?.due_date}
						onChange={(e) => {
							setState((d) => {
								d.due_date = e;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Data de Pagamento"}>
					<DatePicker
						style={{
							width: "100%",
						}}
						format={"DD/MM/YYYY"}
						value={state?.payment_date}
						onChange={(e) => {
							setState((d) => {
								d.payment_date = e;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Status do Pagamento"}>
					<Select
						value={state?.payment_status}
						onChange={(e) => {
							setState((d) => {
								d.payment_status = e;
							});
						}}
					>
						<Select.Option value={"paid"}>Pago</Select.Option>
						<Select.Option value={"due"}>A vencer</Select.Option>
						<Select.Option value={"overdue"}>Vencido</Select.Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default Users;
