import { useQuery, gql, useMutation } from "@apollo/client";
import {
	Layout,
	Typography,
	Descriptions,
	Tabs,
	Form,
	Select,
	Row,
	Col,
	DatePicker,
	Button,
	Table,
	Tag,
	notification,
	Upload,
	Tooltip,
	Modal,
} from "antd";
import { useHistory } from "react-router-dom";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import InputNumber from "components/InputNumber";
import moment from "moment";
import { EditOutlined, FileOutlined, UploadOutlined } from "@ant-design/icons";
import { useImmer } from "use-immer";
import { useEffect } from "react";

const { Content } = Layout;
const { Title } = Typography;

function User(props) {
	const history = useHistory();
	const { id } = props.match.params;

	const [state, setState] = useImmer({});
	const [invoiceModal, setInvoiceModal] = useImmer({
		visible: false,
	});

	const { data, loading, error } = useQuery(
		gql`
	query User($user_id: String!) {
		User(user_id: $user_id) {
			_id
			name
			email
			cpf
			cnpj
			phone
			status
			plan
			notes
			trade_name
			company_name
		}
	}
	`,
		{
			fetchPolicy: "no-cache",
			variables: {
				user_id: id,
			},
		}
	);

	const [saveMRR] = useMutation(
		gql`
		mutation UserMRR($user_id: String!, $input: UserMRR_Input) {
			UserMRR(user_id: $user_id, input: $input)
		}
	`,
		{
			onCompleted: (response) => {
				if (response?.UserMRR) {
					notification.success({
						message: "Informações salvas com sucesso!",
					});
				} else {
					notification.error({
						message: "Não foi possível salvar no momento.",
					});
				}
			},
			onError: (err) => {
				console.log(err);
				notification.error({
					message: "Não foi possível salvar no momento.",
				});
			},
		}
	);

	const StateDateConversion = () => {
		const datesIndexes = [
			"amount_temporary_period_end",
			"amount_temporary_period_start",
		];

		const toDate = (array) => {
			Object.keys(array).map((key) => {
				if (datesIndexes.indexOf(key) > -1) {
					array[key] = moment(array[key]);
				}
			});
			return array;
		};

		const toString = (array) => {
			Object.keys(array).map((key) => {
				if (datesIndexes.indexOf(key) > -1) {
					array[key] = array[key].toISOString();
				}
			});
			return array;
		};

		return {
			toDate,
			toString,
		};
	};

	const mrr = useQuery(
		gql`
		query UserMRR($user_id: String!) {
			UserMRR(user_id: $user_id) {
				contract_status
				invoice_monthly_date
				payment_method
				amount
				amount_temporary
				amount_temporary_period_start
				amount_temporary_period_end
			}
		}
	`,
		{
			variables: {
				user_id: id,
			},
			onCompleted: (response) => {
				console.log(response);
				if (response?.UserMRR) {
					let stateResponse = { ...response.UserMRR };
					stateResponse = StateDateConversion().toDate(stateResponse);
					setState(stateResponse);
				}
			},
		}
	);

	const invoices = useQuery(
		gql`
		query UserMRRInvoices($user_id: String!) {
			UserMRRInvoices(user_id: $user_id) {
				_id
				invoice_duedate
				invoice_paymentdate
				invoice_status
				payment_status
				amount
				file
				createdAt
				updatedAt
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
			variables: {
				user_id: id,
			},
		}
	);

	const save = async () => {
		let payload = { ...state };
		payload = StateDateConversion().toString(payload);
		await saveMRR({
			variables: {
				input: payload,
				user_id: id,
			},
		});
	};

	const filteredInvoices = (array, status, filter) => {
		if (!array.data?.UserMRRInvoices.length) return [];

		if (filter === "neq") {
			return array.data?.UserMRRInvoices.filter(
				(i) => i.payment_status !== status
			);
		}

		return array.data?.UserMRRInvoices.filter(
			(i) => i.payment_status === status
		);
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>MRR</Title>
				</div>

				{loading ? <Loading /> : null}
				{error ? <Error /> : null}

				{!loading && !error && data ? (
					<div>
						<Descriptions title="Sobre o Parceiro" bordered layout="vertical">
							<Descriptions.Item label="Nome" span={3}>
								{data?.User?.name ?? "-"}
							</Descriptions.Item>
							<Descriptions.Item label="E-mail">
								{data?.User?.email ?? "-"}
							</Descriptions.Item>
							<Descriptions.Item label="Telefone">
								{data?.User?.phone ?? "-"}
							</Descriptions.Item>
							{/* <Descriptions.Item label="Status">
								{data?.User?.status ?? "-"}
							</Descriptions.Item> */}
							<Descriptions.Item label="CNPJ">
								{data?.User?.cnpj ?? "-"}
							</Descriptions.Item>
							<Descriptions.Item label="Razão Social">
								{data?.User?.company_name ?? "-"}
							</Descriptions.Item>
							<Descriptions.Item label="Nome Fantasia">
								{data?.User?.trade_name ?? "-"}
							</Descriptions.Item>
						</Descriptions>
					</div>
				) : null}

				<div style={{ marginTop: 20 }}>
					<Tabs>
						<Tabs.TabPane tab="Informações" key="info">
							<Form layout="vertical">
								<Row gutter={20}>
									<Col span={8}>
										<Form.Item label={"Status do Contrato"}>
											<Select
												value={state?.contract_status}
												onChange={(e) => {
													setState((d) => {
														d.contract_status = e;
													});
												}}
											>
												<Select.Option value={"signed"}>Assinado</Select.Option>
												<Select.Option value={"sent"}>Enviado</Select.Option>
												<Select.Option value={"sending"}>
													Enviando
												</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label={"Data Vencimento Fatura"}>
											<Select
												value={state?.invoice_monthly_date}
												onChange={(e) => {
													setState((d) => {
														d.invoice_monthly_date = e.toString();
													});
												}}
											>
												{Array.from(Array(31).keys()).map((item) => {
													if (item !== 0) {
														return (
															<Select.Option
																key={`invoice_monthly_date_${item}`}
																value={item}
															>
																{item}
															</Select.Option>
														);
													}
												})}
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label={"Forma de Cobrança"}>
											<Select
												value={state?.payment_method}
												onChange={(e) => {
													setState((d) => {
														d.payment_method = e;
													});
												}}
											>
												<Select.Option value={"boleto"}>Boleto</Select.Option>
												<Select.Option value={"card"}>Cartão</Select.Option>
												<Select.Option value={"pix"}>PIX</Select.Option>
											</Select>
										</Form.Item>
									</Col>
									<Col span={8}>
										<Form.Item label={"Valor Cheio"}>
											<InputNumber
												value={state?.amount}
												onChange={(e) => {
													setState((d) => {
														d.amount = e;
													});
												}}
											/>
										</Form.Item>
									</Col>
									<Col span={16}>
										<Row gutter={20}>
											<Col span={12}>
												<Form.Item label={"Valor Provisório e período"}>
													<InputNumber
														value={state?.amount_temporary}
														onChange={(e) => {
															setState((d) => {
																d.amount_temporary = e;
															});
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label={" "}>
													<DatePicker.RangePicker
														style={{
															width: "100%",
														}}
														format={"DD/MM/YYYY"}
														value={[
															state?.amount_temporary_period_start,
															state?.amount_temporary_period_end,
														]}
														onChange={(e) => {
															setState((d) => {
																d.amount_temporary_period_start = e[0];
																d.amount_temporary_period_end = e[1];
															});
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
								</Row>

								<div style={{ textAlign: "right" }}>
									<Button type="primary" onClickCapture={save}>
										Salvar
									</Button>
								</div>
							</Form>
						</Tabs.TabPane>
						<Tabs.TabPane tab="Faturas" key="invoices">
							<Table
								pagination={{
									hideOnSinglePage: true,
								}}
								dataSource={filteredInvoices(invoices, "paid", "neq")}
								columns={[
									{
										key: "invoice_duedate",
										dataIndex: "invoice_duedate",
										title: "Data Vencimento",
										width: 200,
										render: (value) =>
											value ? moment(value).format("DD/MM/YYYY") : "-",
									},
									{
										key: "amount",
										dataIndex: "amount",
										title: "Valor",
										render: (value) =>
											value
												? value.toLocaleString("pt-BR", {
														style: "currency",
														currency: "BRL",
												  })
												: "-",
									},
									{
										key: "payment_status",
										dataIndex: "payment_status",
										title: "Status do Pagamento",
										render: (value) => {
											let label, color;

											switch (value) {
												case "paid":
													label = "Pago";
													color = "green";
													break;
												case "due":
													label = "A vencer";
													color = "gold";
													break;
												default:
													label = "Vencido";
													color = "red";
													break;
											}
											return <Tag color={color}>{label}</Tag>;
										},
									},
									{
										key: "invoice_status",
										dataIndex: "invoice_status",
										title: "Status Nota Fiscal",
										render: (value) => {
											let label;
											switch (value) {
												case "created":
													label = "Emitida";
													break;
												case "to_be_created":
													label = "A emitir";
													break;
												default:
													label = "Enviado contabilidade";
													break;
											}
											return <Tag>{label}</Tag>;
										},
									},

									{
										key: "file",
										dataIndex: "file",
										title: "Arquivo",
										width: 100,
										render: (value) => {
											if (value)
												return (
													<Button
														shape="circle"
														onClick={() => {
															window.open(value);
														}}
													>
														<FileOutlined />
													</Button>
												);

											return null;
										},
									},
									{
										key: "actions",
										dataIndex: "actions",
										title: "Ações",
										width: 100,
										render: (value, row) => {
											return (
												<Tooltip title="Editar">
													<Button
														shape="circle"
														onClick={() => {
															setInvoiceModal({
																visible: true,
																data: row,
															});
														}}
													>
														<EditOutlined />
													</Button>
												</Tooltip>
											);
										},
									},
								]}
							/>

							<div style={{ textAlign: "right", marginTop: 30 }}>
								<Button
									type="primary"
									onClickCapture={() => {
										setInvoiceModal({
											visible: true,
										});
									}}
								>
									Adicionar
								</Button>
							</div>
						</Tabs.TabPane>
						<Tabs.TabPane tab="Histórico de Pagamentos" key="payment-logs">
							<Table
								dataSource={filteredInvoices(invoices, "paid")}
								pagination={{
									hideOnSinglePage: true,
								}}
								columns={[
									{
										key: "invoice_duedate",
										dataIndex: "invoice_duedate",
										title: "Data de Vencimento",
										width: 200,
										render: (value) =>
											value ? moment(value).format("DD/MM/YYYY") : "-",
									},
									{
										key: "invoice_paymentdate",
										dataIndex: "invoice_paymentdate",
										title: "Data de Pagamento",
										width: 200,
										render: (value) =>
											value ? moment(value).format("DD/MM/YYYY") : "-",
									},

									{
										key: "amount",
										dataIndex: "amount",
										title: "Valor",
										render: (value) =>
											value
												? value.toLocaleString("pt-BR", {
														style: "currency",
														currency: "BRL",
												  })
												: "-",
									},
									{
										key: "invoice_status",
										dataIndex: "invoice_status",
										title: "Status",
										render: (value) => {
											return <Tag color="green">Pago</Tag>;
										},
									},
								]}
							/>
						</Tabs.TabPane>
					</Tabs>
				</div>
			</Content>

			<InvoiceModal
				user_id={id}
				refetch={() => {
					invoices.refetch();
				}}
				{...invoiceModal}
				close={() => {
					setInvoiceModal({
						visible: false,
					});
				}}
			/>
		</Layout>
	);
}

function InvoiceModal({ visible, close, data, user_id, refetch }) {
	const [state, setState] = useImmer({});

	const onClose = () => {
		close();
		setState({});
	};

	const save = async () => {
		let payload = { ...state };
		if (payload.invoice_duedate)
			payload.invoice_duedate = payload.invoice_duedate.toString();
		await saveAction({
			variables: {
				input: payload,
				invoice_id: data?._id ? data._id : null,
				user_id,
			},
		});
	};

	useEffect(() => {
		if (visible && data) {
			let { createdAt, updatedAt, _id, file, ...invoiceData } = data;

			if (invoiceData.invoice_duedate)
				invoiceData.invoice_duedate = moment(invoiceData.invoice_duedate);
			setState(invoiceData);
		}
	}, [data, visible]);

	const [saveAction, { loading: loadingSave }] = useMutation(
		gql`
		mutation UserMRRInvoice($user_id: String!, $invoice_id: String, $input: UserMRRInvoice_Input) {
			UserMRRInvoice(user_id: $user_id, invoice_id: $invoice_id, input: $input)
		}
	`,
		{
			onCompleted: (res) => {
				console.log(res);
				if (res?.UserMRRInvoice) {
					notification.success({
						message: "Informações salvas com sucesso",
					});
					refetch();
				} else {
					notification.error({
						message: "Não foi possivel salvar as informações no momento.",
					});
				}
			},
			onError: (err) => {
				console.log(err);
				notification.error({
					message: "Não foi possivel salvar as informações no momento.",
				});
			},
		}
	);

	return (
		<Modal
			visible={visible}
			onCancel={() => {
				onClose();
			}}
			title={"Fatura"}
			footer={[
				<Button key="back" onClick={onClose} disabled={loadingSave}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loadingSave}
					disabled={loadingSave}
					onClick={save}
				>
					Enviar
				</Button>,
			]}
		>
			<Form layout="vertical">
				<Form.Item label={"Data de Vencimento"}>
					<DatePicker
						style={{
							width: "100%",
						}}
						format={"DD/MM/YYYY"}
						value={state?.invoice_duedate}
						onChange={(e) => {
							setState((d) => {
								d.invoice_duedate = e;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Status Nota Fiscal"}>
					<Select
						value={state?.invoice_status}
						onChange={(e) => {
							setState((d) => {
								d.invoice_status = e;
							});
						}}
					>
						<Select.Option value={"created"}>Emitida</Select.Option>
						<Select.Option value={"to_be_created"}>A emitir</Select.Option>
						<Select.Option value={"sent_accountant"}>
							Enviado contabilidade
						</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label={"Status do Pagamento"}>
					<Select
						value={state?.payment_status}
						onChange={(e) => {
							setState((d) => {
								d.payment_status = e;
							});
						}}
					>
						<Select.Option value={"paid"}>Pago</Select.Option>
						<Select.Option value={"due"}>A vencer</Select.Option>
						<Select.Option value={"overdue"}>Vencido</Select.Option>
					</Select>
				</Form.Item>

				{state?.payment_status === "paid" ? (
					<Form.Item label={"Data de Pagamento"}>
						<DatePicker
							style={{
								width: "100%",
							}}
							format={"DD/MM/YYYY"}
							value={state?.invoice_paymentdate}
							onChange={(e) => {
								setState((d) => {
									d.invoice_paymentdate = e;
								});
							}}
						/>
					</Form.Item>
				) : null}

				<Form.Item label={"Valor da Fatura"}>
					<InputNumber
						value={state?.amount}
						onChange={(e) => {
							setState((d) => {
								d.amount = e;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Arquivo"}>
					<div
						style={{
							display: "flex",
							gap: 10,
						}}
					>
						<Upload
							maxCount={1}
							accept={".pdf,.gif,.jpg,.jpeg,.png"}
							beforeUpload={() => false}
							onChange={(e) => {
								setState((d) => {
									d.file = e.file;
								});
							}}
						>
							<Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
						</Upload>
						{data?.file ? (
							<Button
								type="primary"
								onClick={() => {
									window.open(data.file);
								}}
							>
								Ver arquivo
							</Button>
						) : null}
					</div>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default User;
