import { Select, Spin } from "antd";
import { useLazyQuery } from "@apollo/client";
import debounce from "functions/debounce";
import { gql } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
const { Option } = Select;

const UserSearch = ({ onChange, value, disabled, additionalProps, query }) => {
	const el = useRef(null);

	const [term, setTerm] = useState(null);
	const [search, { loading, data: response }] = useLazyQuery(query, {
		fetchPolicy: "no-cache",
	});

	const onSearch = debounce((e) => searchAction(e), 500);

	const searchAction = async (term) => {
		setTerm(term);
		if (term) {
			await search({
				variables: {
					filters: {
						name: term,
					},
					page: 1,
				},
			});
		}
	};

	return (
		<Select
			ref={el}
			disabled={disabled}
			showSearch
			showArrow={false}
			filterOption={false}
			onSearch={(e) => {
				onSearch(e);
			}}
			notFoundContent={loading ? <Spin size="small" /> : null}
			onChange={(value, option) => {
				onChange(value, option.data);
			}}
			placeholder="Buscar usuários"
			value={value ?? null}
			{...additionalProps}
		>
			{(term && response?.Users?.users?.length) &&
				response.Users.users?.map((user) => {
					return (
						<Option key={user._id} value={user._id} data={user}>
							{user.name}
						</Option>
					);
				})}
		</Select>
	);
};

export default UserSearch;
