import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

// const useStore = create((set) => ({
// 	dataSource: null,
// 	setDataDource: (dataSource) => set({ dataSource }),
// }));

const useStore = create(
	subscribeWithSelector((set) => ({
		dataSource: null,
		setDataDource: (dataSource) => set({ dataSource }),
	}))
);

export default useStore;
